.container {
  position: fixed;
  bottom:  0px;

  background-color: #fff;
  height: 65%;
  width: 75%;

  border-top: 4px solid #000;
  border-right: 4px solid #000;
  border-left: 4px solid #000;
  outline: 1px solid #fff;
}

.content {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.gameInfo {
  display: flex;
  flex-direction: column;
}

.teamsTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 40px;
}

.homeTeam,
.awayTeam,
.teamAt {
  font-size: 25px;
  font-weight: bold;
  height: 100%;
  width: 100%;
  text-align: center;
}

.teamAt {
  width: 70px;
}

.gameTimeInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gameDate {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #555;
}

.gameTime {
  padding-top: 2px;
  font-size: 14px;
  font-weight: bolder;
  color: #777;
}

.suggestionsContainer {
  width: 100%;
  height: calc(65vh - 150px);
  overflow-y: scroll;
  padding-top: 20px;
}

.suggestionsTable {
  width: 100%;
  text-align: center;
  border-spacing: 0 3px;
}

.suggestionsTable th {
  color: #555;
}

.suggestionsTable tr:hover {
  background-color: #ddd;
}

@media (max-width:520px) {
  .container {
    width: 85%;
  }

  .content {
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 6px;
  }

  .teamsTitle {
    height: 45px;
  }

  .homeTeam,
  .awayTeam,
  .teamAt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: bold;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .gameDate {
    font-size: 13px;
  }
  .gameTime {
    padding-top: 2px;
    font-size: 12px;
  }

  .suggestionsContainer {
    padding-top: 3px;
    height: calc(65vh - 115px);
    font-size: 11px;
  }

  .tableBookmakerCell {
    font-size: 10px;
  }
}