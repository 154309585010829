.container {
  position: absolute;
  bottom: 0px;
  
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  height: 25px;
  width: 100%;

  background-color: purple;
  border-top: 2px solid #eee;
  border-bottom: 1px solid #000;
}

.container>div {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

@media (max-width:480px) {
  .container {
    display: none;
  }
}