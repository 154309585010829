.container {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  border: 4px solid #000;
  background-color: #fefefe;

  padding: 2px;
  
  min-height: 60px;
  width: 175px;
  margin-bottom: 20px;
  margin-right: 18px;

  box-shadow: 6px 6px 0px #777;
}

.container:hover {
  transform: translate3d(0px, -2px, 0px);
  box-shadow: 6px 8px 0px #777;
}

.container:active {
  transform: translate3d(0px, 2px, 0px);
  box-shadow: 6px 4px 0px #777;
}

.homeTeam, .awayTeam {
  width: 100%;
  height: 39px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homeTeam {
  border-bottom: 2px solid #fefefe;
}

.teamNameCell,
.teamSpreadCell,
.teamPriceCell {
  overflow: hidden;

  width: 84px;
  height: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #e5e5e5;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.teamNameCell,
.teamSpreadCell {
  border-right: 2px solid #fefefe;
}

.teamNameCell {
  font-size: 22px;
  color: #fff;
  font-weight: bolder;
}


@media (max-width:480px) {
  .container {
    border: 3px solid #000;
    height: 29px;
    width: 125px;
    margin-bottom: 15px;
    margin-right: 20px;
  }

  .teamNameCell,
  .teamSpreadCell,
  .teamPriceCell {
    width: 64px;
    height: 29px;
    font-size: 14px;
    font-weight: 500;
  }
}