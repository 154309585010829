h1 {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
} 

h3 { 
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}

p { 
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
} 

blockquote { 
  font-family: Verdana, Geneva, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}

pre { 
  font-family: Verdana, Geneva, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

html, body {
  min-height: 100vh;
  min-height: 100dvh;
  max-height: -webkit-fill-available;
  width: 100%;
}

html {
  overflow: hidden;
}

body {
  position: relative;
}


.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100vh;
  height: 100dvh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
