.container {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  min-width: 300px;
  max-width: 1200px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin-left: auto;
  margin-right: auto;
}

.dateSection,
.timeSection {
  display: flex;
  flex-direction: column;
}

.dateSection {
  padding-top: 20px;
}

.dateContainer {
  padding-top: 10px;
}

.dateTitle {
  display: flex;
  flex-direction: row;
  justify-content: left;

  padding-bottom: 2px;
  border-bottom: 2px solid #000;

  font-weight: bold;
  font-size: 30px;
  color: #000;
}

.timeContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.timeTitle {
  font-size: 12px;
  font-weight: 400;
  color: #333;
  padding-bottom: 3px;
  padding-left: 6px;
}

@media (max-width:480px) {
  .dateTitle {
    font-size: 20px;
  }

  .timeTitle {
    font-size: 10px;
    color: #111;
  }
}