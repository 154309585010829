.container {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
  background-color: #e2e2ee22;
}

.previews {
  margin-left: 25px;
  margin-right: 25px;
  padding-bottom: 100px;
}

.previewDetailContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}